<template>
    <div class="inner-section">
      <card>
        <!-- search section start -->
        <template v-slot:searchHeaderTitle>
          <h4 class="card-title">{{ $t('allocation_dc_office.delivery_order') }} {{ $t('globalTrans.search') }}</h4>
        </template>
        <template v-slot:searchBody>
        <ValidationObserver ref="form" v-slot="{ handleSubmit, reset }">
            <b-form @submit.prevent="handleSubmit(searchData)" @reset.prevent="reset" autocomplete="off"  enctype="multipart/form-data">
          <b-row>
            <b-col xs="12" sm="12" md="4" lg="4" xl="4">
              <ValidationProvider name="Fiscal Year" vid="fiscal_year" rules="required|min_value:1">
                <b-form-group
                  label-for="fiscal_year"
                  slot-scope="{ valid, errors }"
                >
                <template v-slot:label>
                  {{$t('globalTrans.fiscal_year')}} <span class="text-danger">*</span>
                </template>
                <b-form-select
                  plain
                  v-model="search.fiscal_year_id"
                  :options="fiscalYearList"
                  id="fiscal_year"
                  :state="errors[0] ? false : (valid ? true : null)"
                >
                  <template v-slot:first>
                    <b-form-select-option :value='0'>{{$t('globalTrans.select')}}</b-form-select-option>
                  </template>
                </b-form-select>
                <div class="invalid-feedback">
                  {{ errors[0] }}
                </div>
                </b-form-group>
              </ValidationProvider>
            </b-col>
            <b-col xs="12" sm="12" md="4" lg="4" xl="4">
              <ValidationProvider name="Month" vid="month" rules="required|min_value:1">
                <b-form-group
                  label-for="month"
                  slot-scope="{ valid, errors }"
                >
                <template v-slot:label>
                  {{$t('globalTrans.month')}} <span class="text-danger">*</span>
                </template>
                <b-form-select
                  plain
                  v-model="search.month"
                  :options="monthList"
                  id="month"
                  :state="errors[0] ? false : (valid ? true : null)"
                >
                  <template v-slot:first>
                    <b-form-select-option :value='0'>{{$t('globalTrans.select')}}</b-form-select-option>
                  </template>
                </b-form-select>
                <div class="invalid-feedback">
                  {{ errors[0] }}
                </div>
                </b-form-group>
              </ValidationProvider>
            </b-col>
            <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                <ValidationProvider name="Allotment Installment" vid="allotment_count_id" rules="required|min_value:1">
                  <b-form-group
                    label-for="allotment_count_id"
                    slot-scope="{ valid, errors }"
                  >
                  <template v-slot:label>
                    {{$t('allocation_management.allotment_count')}} <span class="text-danger">*</span>
                  </template>
                  <b-form-select
                    plain
                    v-model="search.allotment_count_id"
                    :options="allotmentCount"
                    id="allotment_count_id"
                    :state="errors[0] ? false : (valid ? true : null)"
                  >
                    <template v-slot:first>
                      <b-form-select-option :value='0'>{{$t('globalTrans.select')}}</b-form-select-option>
                    </template>
                  </b-form-select>
                  <div class="invalid-feedback">
                    {{ errors[0] }}
                  </div>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
              <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                <ValidationProvider name="Regional Office" vid="regional_office_id" rules="required|min_value:1">
                  <b-form-group
                    label-for="regional_office_id"
                    slot-scope="{ valid, errors }"
                  >
                    <template v-slot:label>
                      {{$t('allocation_management.regional_office')}} <span class="text-danger">*</span>
                    </template>
                    <b-form-select
                      plain
                      v-model="search.regional_office_id"
                      :options="regionalOfficeList"
                      id="regional_office_id"
                      :state="errors[0] ? false : (valid ? true : null)"
                    >
                      <template v-slot:first>
                        <b-form-select-option :value=0>{{$t('globalTrans.select')}}</b-form-select-option>
                      </template>
                    </b-form-select>
                    <div class="invalid-feedback">
                      {{ errors[0] }}
                    </div>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                  <ValidationProvider name="District" vid="district_id" rules="required|min_value:1">
                    <b-form-group
                      label-for="district_id"
                      slot-scope="{ valid, errors }"
                    >
                    <template v-slot:label>
                      {{$t('globalTrans.district')}} <span class="text-danger">*</span>
                    </template>
                    <b-form-select
                      plain
                      v-model="search.district_id"
                      :options="districtList"
                      id="district_id"
                      :state="errors[0] ? false : (valid ? true : null)"
                    >
                      <template v-slot:first>
                        <b-form-select-option :value='0'>{{$t('globalTrans.select')}}</b-form-select-option>
                      </template>
                    </b-form-select>
                    <div class="invalid-feedback">
                      {{ errors[0] }}
                    </div>
                    </b-form-group>
                  </ValidationProvider>
              </b-col>
            <b-col sm="9">
            </b-col>
            <b-col sm="3" style="text-align: right;">
              <b-button size="sm" type="submit" variant="primary" class="mt-20">
                <i class="ri-search-line"></i> {{ $t('globalTrans.search') }}
              </b-button>
            </b-col>
          </b-row>
          </b-form>
        </ValidationObserver>
        </template>
        <!-- search section end -->
      </card>
      <body-card>
        <!-- table section start -->
        <template v-slot:headerTitle>
          <h4 class="card-title">{{ $t('allocation_dc_office.delivery_order') }} {{ $t('globalTrans.list') }}</h4>
        </template>
        <template v-slot:headerAction>
          <button v-if="dealers.length" style="padding: 0px 6px;" class="btn btn-success btn-sm ml-3" v-b-modal.delivery-form variant=" iq-bg-success mr-1" :title="$t('globalTrans.forward')" @click="clickForward">{{$t('allocation_dc_office.distribution')}} <i class="ri-chat-forward-line"></i></button>
        </template>
        <template v-slot:body>
          <div class="table-wrapper">
            <b-overlay :show="loader">
              <table class="table table-hover table-bordered">
                  <thead>
                      <tr class="bg-primary">
                        <th scope="col" style="width: 10%"><div>{{ $t('globalTrans.select') }}</div></th>
                        <th scope="col" style="width: 10%"><div>{{ $t('globalTrans.sl_no') }}</div></th>
                          <th scope="col" class="text-center"><div>{{ $t('dealer.dealer_name') }}</div></th>
                          <th scope="col" class="text-center"><div>{{ $t('dealer.company_name') }}</div></th>
                          <th scope="col" class="text-center"><div>{{ $t('dealer.mobile') }}</div></th>
                          <th scope="col" class="text-center"><div>{{ $t('allocation_management.no_of_beneficary') }}</div></th>
                      </tr>
                  </thead>
                  <tbody>
                      <template v-if="dealerList.length > 0">
                          <tr v-for="(dealer, index2) in dealerList" :key="'d-' + index2">
                            <td>
                              <b-form-checkbox
                                v-model="dealer.is_select"
                                @change="checkApplication(dealer)"
                              >
                              </b-form-checkbox>
                            </td>
                            <td>{{ $n(index2+1) }}</td>
                              <td>{{ currentLocale == 'bn' ? dealer.name_bn : dealer.name }}</td>
                              <td>{{ currentLocale == 'bn' ? dealer.company_name_bn : dealer.company_name }}</td>
                              <td>{{ dealer.mobile | mobileNumber }}</td>
                              <td>
                                <ValidationProvider name="No Of Beneficiary" :vid="`no_of_beneficiary${index2}`" rules="required" v-slot="{ errors }">
                                  <b-form-group
                                    :label-for="`no_of_beneficiary${index2}`">
                                    <b-form-input
                                      id="no_of_beneficiary"
                                      v-model.number="dealer.no_of_beneficiary"
                                      oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                                      :state="errors[0] ? false : (valid ? true : null)"
                                    ></b-form-input>
                                    <div class="invalid-feedback">
                                      {{ errors[0] }}
                                    </div>
                                  </b-form-group>
                                </ValidationProvider>
                              </td>
                          </tr>
                      </template>
                      <template v-else>
                          <tr>
                              <td colspan="6" align="center">{{ $t('globalTrans.noDataFound') }}</td>
                          </tr>
                      </template>
                  </tbody>
              </table>
            </b-overlay>
          </div>
        </template>
        <!-- table section end -->
      </body-card>
      <b-modal id="details" size="lg" :title="viewTitle" hide-footer :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
       <Details :item="items" :key="viewitemId"/>
      </b-modal>
      <b-modal id="delivery-form" size="lg" :title="deliveryTitle" hide-footer :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
       <Form :item="items" :allotment="allotment" :dealers="dealers" :key="viewitemId"/>
      </b-modal>
    </div>
  </template>
  <script>
  import Form from './Form.vue'
  import Details from './Details.vue'
  import RestApi, { licenseRegistrationServiceBaseUrl } from '@/config/api_config'
  import { getDistrictDealer, getRegionWiseDistrict } from '../../api/routes'
  import ModalBaseMasterList from '@/mixins/list'

  export default {
    mixins: [ModalBaseMasterList],
    components: { Form, Details },
    data () {
      return {
        licenseRegistrationServiceBaseUrl: licenseRegistrationServiceBaseUrl,
        sortBy: '',
        sortDesc: true,
        sortDirection: 'desc',
        search: {
          fiscal_year_id: 0,
          month: 0,
          allotment_count_id: 0,
          delivery_order_no: '',
          district_id: 0,
          regional_office_id: 0,
          arrival_date_from: '',
          arrival_date_to: '',
          admin_user: 1,
          limit: 10
        },
        districtList: [],
        allotmentData: [],
        allotment: '',
        dealerList: '',
        dealers: [],
        items: [],
        loader: false,
        viewitemId: 0,
        actions: {
          edit: false,
          details: true,
          toogle: false,
          delete: false
        }
      }
    },
    created () {
      if (this.$store.state.Auth.activeRoleId === 128) {
        this.search.admin_user = 2
      }
      // this.loadData()
    },
    watch: {
      'search.regional_office_id': function (newVal, oldVal) {
        if (newVal !== oldVal) {
          this.search.district_id = 0
          this.getRegionalDistrict()
        }
      },
      'search.limit': function (newVal, oldVal) {
        if (newVal !== oldVal) {
          this.loadData()
        }
      }
    },
    computed: {
      currentLocale () {
        return this.$i18n.locale
      },
      formTitle () {
        return (this.editItemId === 0) ? this.$t('allocation_management.allotment_order') + ' ' + this.$t('globalTrans.entry') : this.$t('allocation_management.allotment_order') + ' ' + this.$t('globalTrans.modify')
      },
      viewTitle () {
        return (this.editItemId === 0) ? this.$t('allocation_management.allotment_order') + ' ' + this.$t('globalTrans.details') : this.$t('allocation_management.allotment_order') + ' ' + this.$t('globalTrans.modify')
      },
      challanTitle () {
        return this.$t('allocation_dc_office.challan') + ' ' + this.$t('globalTrans.view')
      },
      deliveryTitle () {
        return this.$t('allocation_dc_office.delivery_order') + ' ' + this.$t('globalTrans.entry')
      },
      allotmentCount () {
        return this.$store.state.commonObj.gradeList.map(item => {
          if (this.$i18n.locale === 'bn') {
            return { value: item.value, text: item.text_bn }
          } else {
            return { value: item.value, text: item.text }
          }
        })
      },
      regionalOfficeList () {
        return this.$store.state.CommonService.commonObj.officeList.filter(item => item.status === 1 && item.org_id === 7 && item.is_regional_office === 1)
      },
      fiscalYearList () {
        return this.$store.state.CommonService.commonObj.fiscalYearList.filter(item => item.status === 1).sort((a, b) => b.sorting_order - a.sorting_order).slice(0, 5)
      },
      warehouseList () {
        return this.$store.state.LicenseRegistrationService.commonObj.warehouseList.filter(item => item.status === 1)
      },
      monthList () {
        return this.$store.state.commonObj.monthList.map(item => {
          if (this.$i18n.locale === 'bn') {
            return { value: item.value, text: item.text_bn }
          } else {
            return { value: item.value, text: item.text_en }
          }
        })
      }
    },
    methods: {
      clickForward () {
      },
      editMethodInParent (item, type) {
        if (type === 1) {
          this.edit(item)
        } else if (type === 2) {
          this.changeStatus(8, licenseRegistrationServiceBaseUrl, item)
        } else if (type === 3) {
          this.details(item)
        }
      },

    checkApplication (value) {
      if (value.no_of_beneficiary > 0) {
        if (value.is_select) {
          this.dealers.push({
            application_id: value.application_id,
            id: value.id,
            no_of_beneficiary: value.no_of_beneficiary
          })
        } else {
          this.dealers = this.dealers.filter(item => {
            return item.application_id !== value.application_id
          })
        }
      } else {
        value.is_select = false
        this.$toast.warn({
                title: this.$i18n.locale === 'en' ? 'Beneficiary Count Error..!' : 'সুবিধাভোগী গণনা ত্রুটি..!',
                message: this.$i18n.locale === 'en' ? 'Sorry, Please add Beneficiary First' : 'দুঃখিত, অনুগ্রহ করে প্রথমে সুবিধাভোগী যোগ করুন।',
                color: '#F5A623',
                timeOut: 15000

          })
      }
    },
    checkAll (value) {
      if (value) {
        this.dealerList.map(item => {
          return Object.assign(item, { is_select: true })
        })
        const applications = this.listData.map(item => {
          return { application_id: item.application_id, id: item.id, no_of_beneficiary: value.no_of_beneficiary }
        })
        this.dealers = applications
      } else {
        this.dealers = []
        this.listData.map(item => {
          return Object.assign(item, { is_select: false })
        })
      }
    },
      getDistrictName (id) {
            if (id) {
                const obj = this.$store.state.CommonService.commonObj.districtList.find(item => item.value === id)
                if (this.$i18n.locale === 'bn') {
                    return obj.text_bn
                } else {
                    return obj.text_en
                }
            }
      },
      getMonth (month) {
          const cateObj = this.monthList.find(item => item.value === parseInt(month))
          if (cateObj !== undefined) {
            return cateObj.text
          }
      },
      delivery (item) {
        this.items = item
      },
       getFiscalYear (yearId) {
              const cateObj = this.$store.state.CommonService.commonObj.fiscalYearList.filter(item => item.status === 1).find(item => item.value === yearId)
              if (cateObj !== undefined) {
                  if (this.$i18n.locale === 'bn') {
                      return cateObj.text_bn
                  } else {
                      return cateObj.text_en
                  }
              }
        },
      searchData () {
        this.loadData()
      },
      perMission (item) {
        let per = false
        item.delivery_items.forEach((element, index) => {
          if (element.payment) {
            per = true
          }
        })
        return per
      },
      async getRegionalDistrict () {
        const result = await RestApi.getData(licenseRegistrationServiceBaseUrl, getRegionWiseDistrict, this.search)
        if (result.success) {
          this.allotmentData = result.data
          const districtIds = this.allotmentData.map(item => item.district_id)
          this.districtList = this.$store.state.CommonService.commonObj.districtList.filter(item => {
            return item.status === 1 && districtIds.includes(item.value)
          })
           this.districtList.map((obj, key) => {
              if (this.$i18n.locale === 'bn') {
                  return { value: obj.value, text: obj.text_bn, text_bn: obj.text_bn, text_en: obj.text_en, no_of_beneficiary: 0 }
              } else {
                  return { value: obj.value, text: obj.text_en, text_bn: obj.text_bn, text_en: obj.text_en, no_of_beneficiary: 0 }
              }
          })
        } else {
        }
      },
      async loadData () {
        this.loader = true
        const result = await RestApi.getData(licenseRegistrationServiceBaseUrl, getDistrictDealer, this.search)
        if (result.success) {
          const dealer = result.data
          this.items = result.item
          this.allotment = result.allotment
          this.dealerList = dealer.map(item => ({
                              id: item.id,
                              application_id: item.application_id,
                              is_select: false,
                              name: item.details.applicant_name,
                              name_bn: item.details.applicant_name_bn,
                              company_name: item.details.company_name,
                              company_name_bn: item.details.company_name_bn,
                              mobile: item.details.mobile,
                              no_of_beneficiary: 0
                          }))
        } else {
          this.dealerList = []
        }
        this.loader = false
      }
    }
  }
  </script>
